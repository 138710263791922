import React, { Component} from 'react'
import { Link } from "gatsby"
import Logo from "../images/Logo.png"
import Scroll from './Scroll'
import "./Nav.css"
// import { AnchorLink } from "gatsby-plugin-anchor-links";

import { Menu, X } from 'react-feather'
import { Location } from '@reach/router'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faYoutubeSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { config } from '@fortawesome/fontawesome-svg-core'
// Disable the auto CSS insertion
config.autoAddCss = false


const ListLink = props => (
    <li>
        <Link to={props.to}>{props.children}</Link>
    </li>
);

export class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false,
            backgroundClass: ''
        };
    }

    state = {
      active: false,
      activeSubNav: false,
      currentPath: false,
    }

    handleScroll = () => {
        const { visibilityClass } = this.state;
        if (window.pageYOffset > 300) {
            if (visibilityClass !== 'navbar-shrink') {
                this.setState({ visibilityClass: 'navbar-shrink' });
            }
        } else {
            if (visibilityClass === 'navbar-shrink') {
                this.setState({ visibilityClass: '' });
            }
        };
        const { backgroundClass} = this.state;
        if (window.pageYOffset > 100) {
            if ( backgroundClass !== 'background-color') {
                this.setState({ backgroundClass: 'background-color' });
            }
        } else {
            if (backgroundClass === 'background-color') {
                this.setState({ backgroundClass: '' });
            }
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.setState({ currentPath: this.props.location.pathname })
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }


  handleMenuToggle = () => this.setState({ active: !this.state.active })

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()
  // keyboard events
  handleLinkKeyDown = ev => {
    if (ev.keyCode === 13) {
      this.state.active && this.handleMenuToggle()
    }
  }

  toggleSubNav = subNav =>
    this.setState({
      activeSubNav: this.state.activeSubNav === subNav ? false : subNav
    })
  keyToggleSubNav = (e, subNav) => {
    // key o is for open so you can enter key to open
    if (e.keyCode === 79 || e.keyCode === 27) {
      this.toggleSubNav(subNav)
    }
  }



    render() {
        const { backgroundClass } = this.state;
        const { active} = this.state,
          { subNav } = this.props,

          NavLink = ({to, className, children, ...props }) => (
            <Link
              to={to}
              className={`NavLink ${ to === this.state.currentPath ? 'active' : ''}${className}`}
              onClick={this.handleLinkClick}
              onKeyDown={this.handleLinkKeyDown}
              tabIndex={0}
              aria-label="Navigation"
              role="button"
              {...props}
            >
              {children}
            </Link>
          )

         return (
          <nav className={`Nav ${backgroundClass} ${active ? 'Nav-active' : ''}`} id="navbar">
            <div className='Nav--Container container'>
                <NavLink
                  to='/'
                >
                  <img src={Logo} className='nav-logo' alt='EC Horses'/>
                </NavLink>

                <div  className='Nav--Links' >

                  <div className="navitems">
                    <NavLink to='/About' className="navlink">
                        ABOUT US
                    </NavLink>

                    <NavLink to='#' className="navlink">
                        BREEDING
                      <div id="submenu">
                        <NavLink to='/mares' className="submenu-item" id="submenu-a"> MARES </NavLink>
                        <NavLink to='/youngsters' className="submenu-item" id="submenu-a"> YOUNGSTERS </NavLink>
                      </div>
                    </NavLink>

                    <NavLink to='/sport-horses' className="navLink">
                      SPORT HORSES
                    </NavLink>

                    <NavLink to='#' className="navlink">
                      REFERENCES
                        <div id="submenu">
                          <NavLink to="/references" className="submenu-item" id="submenu-a">
                            REFERENCES
                          </NavLink>
                          <NavLink to="/references-foals" className="submenu-item" id="submenu-a">
                            REFERENCES FOALS
                          </NavLink>
                        </div>
                    </NavLink>

                    <NavLink to='/contact' className="navlink" id="contact-nav">
                      CONTACT
                    </NavLink>

                    <Scroll
                      onClick={_ => this.Scrollto}
                      type="id"
                      element="footer"
                      offset={-120}
                    >
                      <a href="#contact" className="NavLink" id="contactlink"> CONTACT</a>
                    </Scroll>

                  </div>

                  <div className="social-media">
                    <a className="nav-icon" alt="Instagram icon" href="https://www.instagram.com/ec.horses/" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faInstagram} className="fa-fw" />
                    </a>
                    <a className="nav-icon" alt="Facebook icon" href="https://www.facebook.com/ec.horses17" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faFacebookSquare} className="fa-fw" />
                    </a>
                    <a className="nav-icon" alt="Youtube icon" href="https://www.youtube.com/channel/UCSaoMvPuMr3gzUjh32qWGbg" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faYoutubeSquare} className="fa-fw" />
                    </a>
                    <a className="nav-icon" alt="Whatsapp icon" href="https://wa.me/032475967562" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faWhatsapp} className="fa-fw" />
                    </a>

                  </div>


               </div>

                <button
                  className='Button-blank Nav--MenuButton'
                  onClick={this.handleMenuToggle}
                  tabIndex={0}
                  aria-label='Navigation'
                >
                  {active ? <X /> : <Menu size={30}  />}
                </button>
              </div>
            </nav>
           )
         }
      }

      export default ({subNav}) => (
         <Location>{route => <Navigation subNav={subNav} {...route} />}</Location>
      )
